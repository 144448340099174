<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'pathology-Laboratories-Title' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<div class="h-sub theme-color">Laboratory Information Operating System</div>
				<h1>{{'pathology-Laboratories-Title' | translate}}</h1>
				<div class="h-decor"></div>
			</div>
		</div>
		<div class="container">
			<div class="row">
                <div class="col-md-12">
                    <div [innerHTML]="'pathology-Laboratories-Define-Text-1' | translate"></div>
                </div>
				<div class="col-md-12">
					<div class="d-md-flex pt-3" [innerHTML]="('pathology-Laboratories-Define-Text-2' | translate).split('</p>').pop()"></div>
					<div class="pt-3" [innerHTML]="('pathology-Laboratories-Define-Text-2' | translate).split('</p>').shift()"></div>
				</div>
				<div class="col-md-12 pt-3 " [innerHTML]="'pathology-Laboratories-Define-Text-3' | translate"></div>

				<!-- <ul class="marker-list-md">
					<li>Numune Kabul Modülü</li>
					<li>Laboratuvar Modülü
						<ul>
							<li>Makroskopi (istasyon adımı)</li>
							<li>Frozen (istasyon adımı) - Frozen Randevu Sistemi</li>
							<li>Doku Takip (istasyon adımı)</li>
							<li>Doku Gömme (istasyon adımı)</li>
							<li>Kesit (istasyon adımı)</li>
							<li>Sitolojik Süreçler (istasyon adımı)</li>
							<li>Boyama Kapama (istasyon adımı)</li>
							<li>Mikroskopi (istasyon adımı)</li>
						</ul></li>
				</ul>
				<ul class="marker-list-md">
					<li>Arşiv Modülü
						<ul>
							<li>Lam/Preparat</li>
							<li>Kaset/Blok</li>
							<li>Rapor</li>
						</ul></li>
					<li>Hasta Cari Takip Modülü</li>
					<li>Cihaz Takip Modülü</li>
					<li>Laboratuvar Stok Takip Modülü</li>
				</ul> -->
			</div>
		</div>
		<div class="">
			<div class="mt-3 p-2 bg-theme-color2">
				<div class="text-center">
					<h1 class="text-white">{{'home-Section-2-Title' | translate}}</h1>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
</div>