

	<div class="section bg-right bg-norepeat bg-md-none bg-fixed section-bottom-padding section-top-padding" style="background-image: url(./assets/images/bg-bottom-right.png)">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<!-- <div class="h-sub theme-color">What We Offer</div> -->
				<h1>{{ 'home-Section-2-Title' | translate }}</h1>
				<div class="h-decor"></div>
				<div class="text-center max-670 mt-4 mx-md-auto">
				</div>
			</div>
		</div>
		<div class="container">
			<div class="row col-equalH">
				<div *ngFor="let card of 'home-Section-2' | translate" class="col-md-6 col-lg-4">
					<div class="service-card">
						<div class="text-center display-4 pt-3">
							<i *ngIf="!card.isIconSvg" class="{{card.icon}}"></i>
							<img *ngIf="card.isIconSvg" width="56" height="57" src="assets/images/{{card.icon}}" alt="{{card.title}}"/>
						</div>
						<h5 class="service-card-name">{{card.title}}</h5>
						<div class="h-decor"></div>
						<p>{{card.text}}</p>
					</div>
				</div>
			</div>
		</div>
		</div>