import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../services/translate/translate.service';
import {Globals} from '../../globals';


@Component({
  selector: 'app-topheader',
  templateUrl: './topheader.component.html',
  styleUrls: ['./topheader.component.css']
})
export class TopheaderComponent implements OnInit {

  constructor(private translateService:TranslateService,public global:Globals) { }

  ngOnInit(): void {
  }
  translate(lang: string) {
   /*  localStorage.setItem("app-language", lang); */
    this.translateService.use(lang);
   /*  this.router.config = this.globalRouteConfig.routesConfigs[lang]; */
  }
}
