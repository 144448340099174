<app-breadcrumbs breadCrumbName="{{'our-Quality-Certificates' | translate}}"></app-breadcrumbs>
<div class="section page-content-first">
    <div class="container">
        <div class="text-center mb-2  mb-md-3 mb-lg-4">
            <div class="h-sub theme-color">Laboratory Information Operating System</div>
            <h1>{{'our-Quality-Certificates' | translate}}</h1>
            <div class="h-decor"></div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            
    </div>
    <div class="row">
        <div class="col-md">
            <div class="text-center">ISO 27001</div>
            <img type="button" data-toggle="modal" data-target="#iso-27001" class="img-thumbnail cursor-pointer"  src="./assets/images/certificates/1.jpg"/>

            <div id="iso-27001" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img style="width: 100%;" src="./assets/images/certificates/1.jpg" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="text-center">ISO/IEC 20000-1:2011</div>
            <img type="button" data-toggle="modal" data-target="#iso-iec-20000-1-2011" class="img-thumbnail cursor-pointer" src="./assets/images/certificates/2.jpg"/>
            <div id="iso-iec-20000-1-2011" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img style="width: 100%;" src="./assets/images/certificates/2.jpg" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="text-center">TS ISO/IEC 15504 Spice</div>
            <img type="button" data-toggle="modal" data-target="#ts-iso-iec-15504-spice" class="img-thumbnail cursor-pointer" src="./assets/images/certificates/3.jpg"/>
            <div id="ts-iso-iec-15504-spice" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <img style="width: 100%;" src="./assets/images/certificates/3.jpg" class="img-responsive">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>