<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'announcements' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="row">
				<div class="col-lg-9 aside">
					<div class="blog-post blog-post-single">
						<div class="blog-post-info">
							<div class="post-date">{{announcement.date.substring(0, 2)}}<span>{{announcement.date.substring(3,5)}}</span></div>
							<div>
								<h2 class="post-title"><a>{{announcement.title}}</a></h2>
								<div class="post-meta">
									<div class="post-meta-author"><a class="theme-color"><i>LIOS</i></a></div>
								</div>
							</div>
						</div>
						<div class="post-image">
							<img [src]="announcement.img" [alt]="announcement.title">
						</div>
						<div class="post-text" [innerHTML]="announcement.text"></div>
					</div>
				</div>
				<div class="col-lg-3 aside-left mt-5 mt-lg-0">
					<div class="side-block">
						<h3 class="side-block-title">{{'announcements' |translate}}</h3>
						<div *ngFor="let announcement of 'announcements-List' | translate" class="blog-post post-preview">
							<div class="post-image">
								<a routerLink="duyurular/{{announcement.id}}"><img [src]="announcement.img" alt=""></a>
							</div>
							<div>
								<h4 class="post-title"><a routerLink="/Duyurular/{{announcement.id}}">{{announcement.title}}</a></h4>
								<div class="post-meta">
									<div class="post-meta-author text-nowrap"><a class="theme-color"><i class="theme-color">LIOS</i></a></div>
									<div class="post-meta-date text-nowrap"><i class="icon icon-clock3"></i>{{announcement.date}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
</div>