import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {GlobalRouteConfig} from './global-route-config'


const routes: Routes = [

];

@NgModule({
  imports: [RouterModule.forRoot(new GlobalRouteConfig().routesConfigs,{})],
  exports: [RouterModule],
  providers: [GlobalRouteConfig],
})
export class AppRoutingModule { }
