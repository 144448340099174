<div class="header-topline">
    <div class="container">
        <div class="row align-items-center">
            <ul class="top-left-language">
                <li>
                    <a class="cursor-pointer" (click)="translate('tr')">TR</a>
                </li>
                <li>
                    <a class="cursor-pointer" (click)="translate('en')">EN</a>
                </li>
            </ul>
            <div class="col-auto ml-auto d-flex align-items-center">
                    <span class="header-social">
                        <a target="_blank" href="{{global.socialLinks['fb']}}" class="hovicon"><i class="icon-facebook-logo-circle"></i></a>
                        <a target="_blank" href="{{global.socialLinks['in']}}" class="hovicon"><i class="icon-instagram-circle"></i></a>
                    </span>
            </div>
        </div>
    </div>
</div>