import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approval-support-system',
  templateUrl: './approval-support-system.component.html',
  styleUrls: ['./approval-support-system.component.css']
})
export class ApprovalSupportSystemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
