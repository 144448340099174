import { Injectable } from '@angular/core';

@Injectable()

export class Globals {
  title: string = "Lios | Laboratory Information Operating System | {title}";
  socialLinks = { "fb": "https://www.facebook.com/profile.php?id=100012234120054","in": "https://www.instagram.com/lios.arbe/" };
  slider = [];
  header = [];
  announcementList = [];
  announcement = [];

}
export let appLanguage:string = 'tr';//localStorage.getItem("app-language");