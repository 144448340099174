import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../../globals';

@Component({
  selector: 'app-announcement-detail',
  templateUrl: './announcement-detail.component.html',
  styleUrls: ['./announcement-detail.component.css']
})
export class AnnouncementDetailComponent implements OnInit {
  id: number;
  announcement: any;

  constructor(private route: ActivatedRoute,public globals :Globals) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = +params['id']; 
      this.announcement = this.globals.announcementList.filter(a=>a.id===this.id).shift();
   });
  }

}
