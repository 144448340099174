import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pathology-laboratories',
  templateUrl: './pathology-laboratories.component.html',
  styleUrls: ['./pathology-laboratories.component.css']
})
export class PathologyLaboratoriesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
