<div class="section mt-0">
	<header class="header">
		<!-- Hedar quicklinks-->
		<app-topheader></app-topheader>
		<div class="header-content">
			<div class="container">
				<div class="row align-items-lg-center">
					<button class="navbar-toggler collapsed" data-toggle="collapse" data-target="#navbarNavDropdown">
						<span class="icon-menu"></span>
					</button>
					<div class="col-lg-auto col-lg-2 d-flex align-items-lg-center">
						<a routerLink="/" class="header-logo"><img src="./assets/images/logo.png" alt="" class="img-fluid"></a>
					</div>
					<div class="col-lg ml-auto header-nav-wrap">
						<div class="header-nav js-header-nav">
							<nav class="navbar navbar-expand-lg btco-hover-menu">
								<div class="collapse navbar-collapse justify-content-center" id="navbarNavDropdown">
									<ul class="navbar-nav">
										<li *ngFor="let menu of 'header' | translate" class="nav-item">
											<a [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact: true}" (click)="setTitle(menu.pageTitle)" class="nav-link" routerLink="{{menu.rootName}}">{{menu.name}}</a>
										</li>
									</ul>
								</div>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</div>