import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SendFormService} from '../../services/forms/send-form.service';

@Component({
  selector: 'app-home-section4',
  templateUrl: './home-section4.component.html',
  styleUrls: ['./home-section4.component.css']
})
export class HomeSection4Component implements OnInit {

  private SERVER_URL = "https://Server.lios.com.tr:85/api/Arbe/SendDemoRequest";
  submitted = false;
  form: FormGroup;
  formMessage = "";
  cites:string[];
  constructor(public fb: FormBuilder,private sendFormService:SendFormService) { 
    this.formMessage ="";
  }
  ngOnInit(): void { 
    this.setCities();
    this.form = this.fb.group({
      nameSurname: ['',Validators.required],
      city: ['',Validators.required],
      district: ['Çankaya'],
      institution: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      phone1: ['',Validators.required],
      phone2: ['',Validators.required],
      program: ['Lios'],
      notes: ['',Validators.required],

    });
    
  }
  // Form alanlarına erişim
  get f() { return this.form.controls; }

  submitForm() {
    this.submitted = true;
    // valid değilse  
    if (this.form.invalid) {
        return;
    }
    
    this.sendFormService.sendForm(this.SERVER_URL,this.form.value).subscribe((response=>{
      if(response && response["Message"])
      {
        this.formMessage = response["Message"];
        response["Code"] == "0000" ? this.reset():null;
        this.submitted = false;
      }
      else{
        this.formMessage = "Demo talebi gönderilirken hata meydana geldi.";
      }
    }));

  }
  reset() {
    this.form.reset();
  }

  setCities() {
    this.cites = ["ADANA", "ADIYAMAN", "AFYON", "AĞRI", "AMASYA", "ANKARA", "ANTALYA", "ARTVİN", "AYDIN", "BALIKESİR", "BİLECİK", "BİNGÖL", "BİTLİS", "BOLU", "BURDUR", "BURSA", "ÇANAKKALE", "ÇANKIRI", "ÇORUM", "DENİZLİ", "DİYARBAKIR", "EDİRNE", "ELAZIĞ", "ERZİNCAN", "ERZURUM", "ESKİŞEHİR", "GAZİANTEP", "GİRESUN", "GÜMÜŞHANE", "HAKKARİ", "HATAY", "ISPARTA", "İÇEL", "İSTANBUL", "İZMİR", "KARS", "KASTAMONU", "KAYSERİ", "KIRKLARELİ", "KIRŞEHİR", "KOCAELİ", "KONYA", "KÜTAHYA", "MALATYA", "MANİSA", "KAHRAMANMARAŞ", "MARDİN", "MUĞLA", "MUŞ", "NEVŞEHİR", "NİĞDE", "ORDU", "RİZE", "SAKARYA", "SAMSUN", "SİİRT", "SİNOP", "SİVAS", "TEKİRDAĞ", "TOKAT", "TRABZON", "TUNCELİ", "ŞANLIURFA", "UŞAK", "VAN", "YOZGAT", "ZONGULDAK", "AKSARAY", "BAYBURT", "KARAMAN", "KIRIKKALE", "BATMAN", "ŞIRNAK", "BARTIN", "ARDAHAN", "IĞDIR", "YALOVA", "KARABÜK", "KİLİS", "OSMANİYE", "DÜZCE",];
  }
}
