<app-breadcrumbs breadCrumbName="{{'our-References'| translate}}"></app-breadcrumbs>
<div class="section page-content-first mb-3">
    <div class="container">
        <div class="text-center mb-2  mb-md-3 mb-lg-4">
            <div class="h-sub theme-color">Laboratory Information Operating System</div>
            <h1>{{'our-References'| translate}}</h1>
            <div class="h-decor"></div>
            <div class="text-center max-670 mt-4 mx-md-auto">
                <p>{{'our-References-Title-Text'| translate}}</p>
            </div>
        </div>
    </div>
    <div class="container">
            <div class="row ">
                <div *ngFor="let reference of references" class="department-tab2 col-md-4">
                    <!-- <div class="department-tab2-number">1</div> -->
                    <div class="text-center display-4 pt-3">
                        <img class="img-fluid" [src]="reference.img">
                    </div>
                    <div class="department-tab2-text">{{reference.name}}</div>
                    <div class="department-tab2-decor"></div>
                </div>
            </div>
<!--             <div class="card border p-2 text-center" *ngFor="let reference of references" style="width: 18rem;">
                <div class="card-body">
                    <h5 class="card-title">{{reference.name}}</h5>
                </div>
            </div> -->
    </div>
</div>