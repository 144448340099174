import { Injectable } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ContactComponent } from './components/contact/contact.component';
import { OurReferencesComponent } from './components/our-references/our-references.component';
import { OurCertificatesComponent } from './components/our-certificates/our-certificates.component';
import { BiochemistryLaboratoriesComponent } from './components/detail-pages/biochemistry-laboratories/biochemistry-laboratories.component';
import { GeneticLaboratoriesComponent } from './components/detail-pages/genetic-laboratories/genetic-laboratories.component';
import { PathologyLaboratoriesComponent } from './components/detail-pages/pathology-laboratories/pathology-laboratories.component';
import { ApprovalSupportSystemComponent } from './components/detail-pages/approval-support-system/approval-support-system.component';
import { AnnouncementDetailComponent } from './components/announcement-detail/announcement-detail.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { NotfoundComponent } from './components/notfound/notfound.component';
@Injectable()
export class GlobalRouteConfig {
        routesConfigs = [
        { path: '', 
          children :[{path:'',component:HomeComponent},{path:'en/home',component:HomeComponent}] },
        { path: 'Hakkimizda', component: AboutComponent,data:{} },
        { path: 'Referanslarimiz', component: OurReferencesComponent,data:{} },
        { path: 'Kalite-Sertifikalarimiz', component: OurCertificatesComponent,data:{} },
        { path: 'Klinik-Biyokimya-Laboratuvarlari', component: BiochemistryLaboratoriesComponent,data:{} },
        { path: 'Genetik-Laboratuvarlari', component: GeneticLaboratoriesComponent,data:{} },
        { path: 'Patoloji-Laboratuvarlari', component: PathologyLaboratoriesComponent,data:{} },
        { path: 'Lios-Onay-Destek-Sistemi', component: ApprovalSupportSystemComponent,data:{} },
        { path: 'Duyurular', component: AnnouncementsComponent,data:{} },
        { path: 'Duyurular/:id', component: AnnouncementDetailComponent,data:{} },
        { path: 'Iletisim', component: ContactComponent,data:{rootName:"İletişim"} },
        { path: '404', component: NotfoundComponent, data: { } },
        { path: '**', redirectTo: '/404' }
      ]

  }