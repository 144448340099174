<!--section Demo Talebi-->
<div class="section bg-norepeat bg-bottom bg-left bg-md-none bg-fixed section-bottom-padding-half section-top-padding pt-3" style="background-image: url(./assets/images/bg-bottom-left2.png)">
    <div class="banner-appointment-form">
        <div class="container">
            <div class="row no-gutters">
                <div class="col-sm-5 col-lg-5 order-2 order-sm-2 mt-3 mt-md-0">
                    <img src="./assets/images/content/banner-appointment.png" alt="" class="banner-appointment-form-image">
                </div>
                <div class="col-sm-7 col-lg-7 order-1 order-sm-1 d-flex">
                    <div class="pt-2 pt-lg-6 px-lg-3">
                        <div class="title-wrap-alt text-center">
                            <h2 class="double-title double-title--vcenter double-title--center" data-title="Lios" [innerHTML]="'home-Section-4-Title' | translate"></h2>
                        </div>
                        <p>{{'home-Section-4-Text' | translate}}</p>
                        <div class="messageForm animated flash" *ngIf="formMessage">
							<p class="successform">{{formMessage}}</p>
						</div>
                        <form class="contact-form" [formGroup]="form" (ngSubmit)="submitForm()">
                            <div class="row row-sm-space mt-15">
                                <div class="col-sm-6 mt-15 mt-sm-0">
                                    <input type="text" class="form-control" name="nameSurname" formControlName="nameSurname"   placeholder="{{'form-Name-Surname'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.nameSurname.errors }">
                                    <div *ngIf="submitted && f.nameSurname.errors" class="errorform">
                                        <div *ngIf="f.nameSurname.errors.required">{{'form-Required-Name-Surname' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <!-- <input type="text" class="form-control" name="city" formControlName="city"  placeholder="{{'form-City'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"> -->
                                    <select class="form-control" name="city" formControlName="city" placeholder="{{'form-City'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                        <option value="" disabled selected hidden>{{'form-City'|translate}}*</option>
                                        <option *ngFor="let cityName of cites" value="{{cityName}}">{{cityName}}</option>
                                    </select>
                                    <div *ngIf="submitted && f.city.errors" class="errorform">
                                        <div *ngIf="f.city.errors.required">{{'form-Required-City' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-sm-space mt-15">
                                <div class="col-sm-6 mt-15 mt-sm-0">
                                    <input type="text" class="form-control" name="name" formControlName="institution"  placeholder="{{'form-Institution-Name'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.institution.errors }">
                                    <div *ngIf="submitted && f.institution.errors" class="errorform">
                                        <div *ngIf="f.institution.errors.required">{{'form-Required-Institution-Name' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" name="phone" formControlName="phone1" placeholder="{{'form-Institution-Phone'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.phone1.errors }">
                                    <div *ngIf="submitted && f.phone1.errors" class="errorform">
                                        <div *ngIf="f.phone1.errors.required">{{'form-Required-Institution-Phone' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-sm-space mt-15">
                                <div class="col-sm-6 mt-15 mt-sm-0">
                                    <input type="email" class="form-control" name="mail" formControlName="email" placeholder="{{'form-Email'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                    <div *ngIf="submitted && f.email.errors" class="errorform">
                                        <div *ngIf="f.email.errors.required">{{'form-Required-Email' | translate}}</div>
                                        <div *ngIf="f.email.errors.email">{{'form-Required-Email-Valid' | translate}}</div>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" name="phone" formControlName="phone2" placeholder="{{'form-Phone'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.phone2.errors }">
                                    <div *ngIf="submitted && f.phone2.errors" class="errorform">
                                        <div *ngIf="f.phone2.errors.required">{{'form-Required-Phone' | translate}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-15">
                                <textarea class="form-control" name="message" formControlName="notes" placeholder="{{'form-Notes'|translate}}*" [ngClass]="{ 'is-invalid': submitted && f.notes.errors }"></textarea>
                                <div *ngIf="submitted && f.notes.errors" class="errorform">
                                    <div *ngIf="f.notes.errors.required">{{'form-Required-Notes' | translate}}</div>
                                </div>
                            </div>
                            <div class="mt-2 mt-lg-4 text-center">
                                <button type="submit" class="btn"><i class="icon-right-arrow"></i><span>{{'form-Send-Demo' | translate}}</span><i class="icon-right-arrow"></i></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--//section Demo Talebi-->