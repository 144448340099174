<div class="quickLinks-wrap d-none d-lg-flex">
    <div class="quickLinks">
        <div class="container">
            <div class="row no-gutters">
                <div class="col">
                    <a href="#" class="link">
                        <i class="icon-placeholder"></i><span>{{'location' | translate}}</span></a>
                    <div class="link-drop p-0">
                        <div class="google-map"><iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12245.983532597742!2d32.8176755!3d39.8855286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa71f79182e32a58c!2zQXJiZSBZYXrEsWzEsW0!5e0!3m2!1str!2str!4v1597677809207!5m2!1str!2str"></iframe></div>
                    </div>
                </div>
                <div class="col">
                    <a href="#" class="link">
                        <i class="icon-clock"></i><span>{{'working-Time' | translate}}</span>
                    </a>
                    <div class="link-drop">
                        <h5 class="link-drop-title"><i class="icon-clock"></i>{{'working-Time' | translate}}</h5>
                        <table class="row-table">
                            <tr>
                                <td><i>{{'day-1' | translate}}-{{'day-5' | translate}}</i></td>
                                <td>08:30 - 18:00</td>
                            </tr>
                            <tr>
                                <td><i>{{'day-6' | translate}}</i></td>
                                <td>{{'close' | translate}}</td>
                            </tr>
                            <tr>
                                <td><i>{{'day-7' | translate}}</i></td>
                                <td>{{'close' | translate}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col">
                    <a href="#" class="link">
                        <i class="icon-pencil-writing"></i><span>{{'request-Demo' | translate}}</span>
                    </a>
                    <div class="link-drop">
                        <h5 class="link-drop-title"><i class="icon-pencil-writing"></i> {{'request-Demo' | translate}}</h5>
                        <form [formGroup]="form" (ngSubmit)="submitForm()">
                            <div class="successform">
                                <p>{{formMessage}}</p>
                            </div>
                            <div  class="errorform" *ngIf="submitted">
                                <p *ngIf="f.nameSurname.errors || f.city.errors || f.email.errors.required || f.phone1.errors || f.phone2.errors || f.institution.errors || f.notes.errors">
                                    {{'form-Required-All' | translate}}
                                </p>
                                <span *ngIf="f.email.errors && f.email.errors.email">{{'form-Required-Email-Valid' | translate}}</span>
                            </div>
                            <div class="row row-sm-space mt-1">
                                <div class="col">
                                    <div class="input-group">
                                        <span>
                                        <i class="icon-user"></i>
                                        </span>
                                        <input name="nameSurname" type="text" formControlName="nameSurname" [ngClass]="{ 'is-invalid': submitted && f.nameSurname.errors }" class="form-control" placeholder="{{'form-Name-Surname' | translate}}*"/>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group">
                                            <span>
                                            <i class="icon-globe"></i>
                                        </span>
                                        <input name="city" type="text" formControlName="city" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" placeholder="{{'form-City' | translate}}*"/>
                                    </div>
                                </div>
                            </div>
                            <div class="row row-sm-space mt-1">
                                <div class="col">
                                    <div class="input-group">
                                            <span>
                                            <i class="icon-email2"></i>
                                        </span>
                                        <input name="email" type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="{{'form-Email' | translate}}*"/>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-group">
                                            <span>
                                            <i class="icon-smartphone"></i>
                                        </span>
                                        <input name="requestphone" type="text" class="form-control" formControlName="phone1" [ngClass]="{ 'is-invalid': submitted && f.phone1.errors }" placeholder="{{'form-Phone' | translate}}*"/>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group mt-1">
                                <span>
                                    <i class="icon-star"></i>
                                </span>
                                <input name="requestname" type="text" class="form-control" formControlName="institution" [ngClass]="{ 'is-invalid': submitted && f.institution.errors }" placeholder="{{'form-Institution-Name' | translate}}*"/>
                            </div>
                            <div class="input-group mt-1">
                                <span>
                                    <i class="icon-telephone"></i>
                                </span>
                                <input name="requestphone2" type="text" class="form-control" formControlName="phone2" [ngClass]="{ 'is-invalid': submitted && f.phone2.errors }" placeholder="{{'form-Institution-Phone' | translate}}*"/>
                            </div>
                            <div class="input-group mt-1">
                                <span>
                                    <i class="icon-black-envelope"></i>
                                </span>
                                <textarea name="requestmessage" type="text" rows="4" formControlName="notes"  class="form-control" [ngClass]="{ 'is-invalid': submitted && f.notes.errors }" placeholder="{{'form-Notes' | translate}}*"></textarea>
                            </div>
                            <div class="text-right mt-2">
                                <button type="submit" class="btn btn-sm btn-hover-fill">{{'form-Send-Demo' | translate}}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col">
                    <a href="#" class="link">
                        <i class="fa fa-code"></i><span>Arbe - Lios</span>
                    </a>
                    <div class="link-drop">
                        <h5 class="link-drop-title"><img class="w-50" src="./assets/images/arbe-yazilim.png" /></h5>
                        <p>{{'arbe-Lios-Define' | translate}}</p>
                        <p class="text-right"><a href="https://www.arbeyazilim.com.tr/" target="_blank" class="btn btn-sm btn-hover-fill link-inside">Arbe</a></p>
                    </div>
                </div>
                <div class="col">
                    <a href="#" class="link">
                        <i class="fa fa-comments"></i><span>{{'announcements' | translate}}</span>
                    </a>
                    <div class="link-drop">
                        <h5 class="link-drop-title"><i class="theme-color fa fa-comments"></i> {{'announcements' | translate}}</h5>
                        <table class="row-table">
                            <tr *ngFor="let announcement of 'announcements-List' | translate">
                                <td><a routerLink="/Duyurular/{{announcement.id}}">{{announcement.title.substring(0,20)}}</a></td>
                                <td>{{announcement.date}}</td>
                            </tr>
                            <tr *ngIf="!isHaveAnnouncement">{{'not-announcements'|translate}}</tr>
                        </table>
                    </div>
                </div>
                <div class="col">
                    <a href="#" class="link">
                        <i class="fa fa-phone"></i><span>{{'contact-Information'| translate}}</span></a>
                    <div class="link-drop">
                        <h5 class="link-drop-title"><i class="theme-color  fa fa-phone"></i> {{'contact-Information'| translate}}</h5>
                        <ul class="icn-list">
                            <li><i class="icon-placeholder2"></i><span> Cevizlidere Mah. Cevizlidere Cad. Gökkuşağı Apt. No:42/11 <br>Çankaya ANKARA</span></li>
                            <li><i class="icon-telephone"></i><span class="phone">0(850) 302 54 67<br>0(312) 472 63 79</span></li>
                            <li><i class="icon-black-envelope"></i><a href="mailto:info@lios.com.tr">info@lios.com.tr</a></li>
                        </ul>
                        <p class="text-right mt-2"><a href="\contact" class="btn btn-sm btn-hover-fill link-inside">{{'contact'| translate}}</a></p>
                    </div>
                </div>
                <div class="col col-close"><a href="#" class="js-quickLinks-close"><i class="icon-top" data-toggle="tooltip" data-placement="top" title="Kapat"></i></a></div>
            </div>
        </div>
    </div>
</div>