<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'about-Us' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<div class="h-sub theme-color">Laboratory Information Operating System</div>
				<h1>{{'about-Us' | translate}}</h1>
				<div class="h-decor"></div>
			</div>
		</div>
		<div class="container">
			<div class="row">
				<div class="col-lg-6 text-center text-lg-left pr-md-4">
					<img src="./assets/images/content/banner-right-1.jpg" class="w-100" alt="">
					<div class="row mt-3">
						<div class="col-6">
							<img src="./assets/images/content/services-03.jpg" class="w-100" alt="">
						</div>
						<div class="col-6">
							<img src="./assets/images/content/services-06.jpg" class="w-100" alt="">
						</div>
					</div>
				</div>
				<div class="col-lg-6 mt-3 mt-lg-0">
					<p>{{'about-Us-Define-Text'| translate}}</p>
					<div class="mt-3 mt-md-7"></div>
					<ul class="marker-list-md">
						<h5>LIOS;</h5>
						<li *ngFor="let spec of 'about-Lios-Spec'| translate">{{spec.item}}</li>
					</ul>
					<div class="mt-3 mt-md-7"></div>
					<!-- <h3>Mission / Vision <span class="theme-color">Statement</span></h3> -->
					<!-- <div class="mt-0 mt-md-4"></div> -->
					<div class="text-2" [innerHTML]="'about-Us-Define-Text-2' | translate">

					</div>
					
				</div>
			</div>
		</div>
		<div class="">
			<div class="mt-3 p-2 bg-theme-color2">
				<div class="text-center">
					<h1 class="text-white">{{'home-Section-2-Title' | translate}}</h1>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
	

</div>