<!-- <app-mainslider></app-mainslider> -->
<app-slider><div class="loading-content">
    <div class="loader-dna">
        <column>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
        </column>
        <column>
            <dash></dash>
            <dash></dash>
            <dash></dash>
            <dash></dash>
            <dash></dash>
            <dash></dash>
            <dash></dash>
            <dash></dash>
        </column>
        <column>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
            <dot></dot>
        </column>
    </div>
</div></app-slider>
<app-home-section1></app-home-section1>
<app-home-section2></app-home-section2>
<app-home-section3></app-home-section3>
<app-home-section4></app-home-section4>


<!-- <div class="modal fade" id="liosDuyuru" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header justify-content-end border-0 p-0">
                <button type="button" class="btn btn-xs btn-outline-dark mt-1 mr-1" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                  </button>
            </div>
            <div class="modal-body pb-2 px-3">
                <p style="line-height: 36px;">
                    LIOS, Arbe Bilgisayar Yazılım Tic. Ltd. Şti. nin marka tescilli ürünüdür.<br>
                    Arbe Yazılım ailesi ortaklı bir idariyapıya sahip ve tüm ortaklar şirket
                    bünyesinde aktif olarak çalışmaktadır.<br>
                    Şirket bünyesinde çalışan bu ortakların herhangi bir başka şirketle resmi ya da gayri resmi hiç bir bağlantısı
                    bulunmamaktadır.<br>
                    Son zamanlarda şirketimizle bağlantısı olmayan şahıslar, ortaklık iddiasında bulunmaktadır.<br>
                    Hizmet verdiğimiz hiç bir kurum, kurum yöneticisi veya kurum personeli şirketimiz adına yetkilendirilmiş
                    değildir.<br>
                    Sektörde Arbe Yazılm ya da LIOS ile herhangi bir ortaklık iddiasında bulunan şahıslara itibar edilmemesi önemle
                    rica olunur.<br>
                    Yanlış anlaşılmaların ve olası soru işaretlerinin ortadan kalkması için bu açıklamaya gerek duyulmuştur.
                    <br>
                    Saygılarımızla
                    <br>
                </p>
                <div class="d-flex justify-content-center">
                    <a class="d-flex justify-content-center" href="https://www.arbeyazilim.com.tr/" target="_blank">
                        <img class="img-fluid w-50" src="./assets/images/arbe-yazilim.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div> -->