import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA,APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopheaderComponent } from './components/topheader/topheader.component';
import { HeaderComponent } from './components/header/header.component';
import { SliderComponent } from './components/slider/slider.component';
import { HomeComponent } from './components/home/home.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainsliderComponent } from './components/mainslider/mainslider.component';
import { AboutComponent } from './components/about/about.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ContactComponent } from './components/contact/contact.component';
import { HomeSection1Component } from './components/home-section1/home-section1.component';
import { HomeSection2Component } from './components/home-section2/home-section2.component';
import { HomeSection3Component } from './components/home-section3/home-section3.component';
import { QuickLinksComponent } from './components/quick-links/quick-links.component';
import { OurReferencesComponent } from './components/our-references/our-references.component';
import { OurCertificatesComponent } from './components/our-certificates/our-certificates.component';
import { HomeSection4Component } from './components/home-section4/home-section4.component';
import { BiochemistryLaboratoriesComponent } from './components/detail-pages/biochemistry-laboratories/biochemistry-laboratories.component';
import { GeneticLaboratoriesComponent } from './components/detail-pages/genetic-laboratories/genetic-laboratories.component';
import { HttpClientModule } from '@angular/common/http';
import { FormBuilder,FormsModule,ReactiveFormsModule } from '@angular/forms';
import {TranslateService} from './services/translate/translate.service'
import { TranslatePipe } from './pipes/translate.pipe';
import { Globals,appLanguage } from './globals';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PathologyLaboratoriesComponent } from './components/detail-pages/pathology-laboratories/pathology-laboratories.component';
import { ApprovalSupportSystemComponent } from './components/detail-pages/approval-support-system/approval-support-system.component';
import { AnnouncementsComponent } from './components/announcements/announcements.component';
import { AnnouncementDetailComponent } from './components/announcement-detail/announcement-detail.component';
import { NotfoundComponent } from './components/notfound/notfound.component';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(appLanguage);
}

@NgModule({
  declarations: [
    AppComponent,
    TopheaderComponent,
    HeaderComponent,
    SliderComponent,
    HomeComponent,
    FooterComponent,
    MainsliderComponent,
    AboutComponent,
    BreadcrumbsComponent,
    ContactComponent,
    HomeSection1Component,
    HomeSection2Component,
    HomeSection3Component,
    QuickLinksComponent,
    OurReferencesComponent,
    OurCertificatesComponent,
    HomeSection4Component,
    BiochemistryLaboratoriesComponent,
    TranslatePipe,
    GeneticLaboratoriesComponent,
    PathologyLaboratoriesComponent,
    ApprovalSupportSystemComponent,
    AnnouncementsComponent,
    AnnouncementDetailComponent,
    NotfoundComponent
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CarouselModule,
    BrowserAnimationsModule
  ],
  providers: [FormBuilder,Globals,{
    provide: APP_INITIALIZER,
    useFactory: setupTranslateFactory,
    deps: [ TranslateService ],
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
