import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-references',
  templateUrl: './our-references.component.html',
  styleUrls: ['./our-references.component.css']
})
export class OurReferencesComponent implements OnInit {

  constructor() { }

  references = [
    { img: "./assets/images/content/synevo.png", name: "SYNEVO" },
    { img: "./assets/images/content/bravo-kani-med-ozbekistan.png", name: "Bravo KANI MED Özbekistan" },
    { img: "./assets/images/content/turkish-lab-azerbeycan.png", name: "Turkish-Lab - Özbekistan" },
    { img: "./assets/images/content/kani-lab-termez-ozbekistan.png", name: "KANI LAB - TERMEZ Özbekistan" },
    { img: "./assets/images/content/turkish-lab-azerbeycan.png", name: "Turkish-Lab - Azerbeycan" },
    { img: "./assets/images/content/medi-park-clinic.png", name: "Medi-Park Clinic - İngiltere" },
    { img: "./assets/images/content/ncmc-kani-med-ozbekistan.png", name: "NCMC - KANI MED - Özbekistan" },
    { img: "", name: "Caspian Alborz Megalab - Iran" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "İstanbul Anadolu Bölgesi Merkezi Genetik Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "İstanbul Avrupa Bölgesi Merkezi Genetik Laboratuvarı" },
    { img: "./assets/images/content/vetest.png", name: "Vetest - Veteriner Teşhis ve Analiz Laboratuvarı" },
    { img: "./assets/images/content/genay.png", name: "GENAY" },
    { img: "./assets/images/content/diagen.png", name: "DİAGEN" },
    { img: "./assets/images/content/akgen-lab.png", name: "AKGEN" },
    { img: "./assets/images/content/bir-lab.png", name: "BİR-LAB Laboratuvarı" },
    { img: "./assets/images/content/alfagen.png", name: "Alfagen Genetik Tanı Merkezi" },
    { img: "./assets/images/content/genklinik.png", name: "GenKlinik Genetik Tanı Merkezi" },
    { img: "./assets/images/content/burgen.png", name: "BURGEN - Genetik Hastalıklar Değerlendirme Merkezi" },
    { img: "./assets/images/content/nucleus.png", name: "Nucleus" },
    { img: "./assets/images/content/starlab.png", name: "Starlab - Arnavutluk" },
    { img: "./assets/images/content/mikrogen.png", name: "Mikrogen Genetik Hastalıklar Tanı Merkezi" },
    { img: "./assets/images/content/omega-gen.png", name: "Omega-Gen" },
    { img: "./assets/images/content/multigen.png", name: "Multigen" },
    { img: "./assets/images/content/intergen.png", name: "İntergen" },
    { img: "./assets/images/content/next-genetic-center.png", name: "NGC - Next Genetic Center" },
    { img: "./assets/images/content/gentan.png", name: "Gentan Genetik Hastalıklar Değerlendirme Merkezi" },
    { img: "./assets/images/content/premed.png", name: "PreMed Prenatal Tanı ve Genetik Laboratuvarı" },
    { img: "./assets/images/content/antalya-genetik-hastaliklar-tani-merkezi.png", name: "Antalya Genetik Hastalıklar Tanı Merkezi" },
    { img: "./assets/images/content/ege-universitesi.png", name: "Ege Üniversitesi Genetik Laboratuvarı" },
    { img: "./assets/images/content/dna-laboratuvarlari.png", name: "DNA Laboratuvarı Genetik Hastalıklar Tanı Merkezi" },
    { img: "./assets/images/content/ozel-erbil-tip-laboratuvari.png", name: "Özel Erbil Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Özel Neolab Laboratuvarı" },
    { img: "./assets/images/content/ozel-deren-laboratuvari.png", name: "Özel Deren Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Özel Doğa Laboratuvarı" },
    { img: "./assets/images/content/erbayraktar-ozel-tip-laboratuvarlari.png", name: "Erbayraktar Özel Tıp Laboratuvarları" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Trabzon Fatih Devlet Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Trabzon Kanuni Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Başakşehir Çam ve Sakura Şehir Hastanesi" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "İzmir Şehir Hastanesi" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Etlik Şehir Hastanesi" },
    { img: "./assets/images/content/firat-universitesi-hastanesi.png", name: "Fırat Üniversitesi Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Kastamonu Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Erzincan Gazi Mengücek Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/sanko-universitesi.png", name: "Gaziantep Özel Sanko Hastanesi" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Erzurum Bölge Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/bezmiâlem-vakif-universitesi.png", name: "Bezmiâlem Vakıf Üniversitesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Şanlıurfa Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Şanlıurfa Mehmet Akif İnan Eğitim ve Araştırma Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/hatay-mustafa-kemal-universitesi.png", name: "Hatay Mustafa Kemal Üniversitesi Hastanesi - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Ankara Halk Sağlığı Laboratuvarı - (LBYS ve Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Erzincan Halk Sağlığı Laboratuvarı - (Onay Destek Sistemi)" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Urfa Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Adana Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Antalya Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Bursa Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Balıkesir Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Çanakkale Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Diyarbakır Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Elazığ Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Erzurum Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Gaziantep Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Malatya Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Manisa Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Muğla Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Isparta Halk Sağlığı Laboratuvarı" },
    { img: "./assets/images/content/saglik-bakanligi.png", name: "Sakarya Halk Sağlığı Laboratuvarı" },
];
  

  ngOnInit(): void {
  }

}
