<div class="footer mt-0">
	<div class="container">
		<div class="row py-1 py-md-2 px-lg-0">
			<div class="col-sm-6 col-lg-4">
				<h3>{{'contact-Information'|translate}}</h3>
				<div class="h-decor"></div>
				<ul class="icn-list">
					<li><i class="icon-placeholder2"></i> Cevizlidere Mah. Cevizlidere Cad. Gökkuşağı Apt. No:42/11 Çankaya/ANKARA
						<br>
						<a target="_blank" href="https://www.google.com/search?q=Cevizlidere+Mah.+Ceyhun+Atuf+Kansu+Cad.+No%3A181%2F3+%C3%87ankaya+ANKARA&oq=Cevizlidere+Mah.+Ceyhun+Atuf+Kansu+Cad.+No%3A181%2F3+%C3%87ankaya+ANKARA&aqs=chrome..69i57.201j0j7&sourceid=chrome&ie=UTF-8#" class="btn btn-xs btn-gradient">
							<i class="icon-placeholder2"></i><span>{{'footer-Map-Directions'|translate}}</span><i class="icon-right-arrow"></i></a>
					</li>
					<li><i class="icon-telephone"></i><b><span class="phone"><span class="text-nowrap">0(312) 472 63 79</span></span></b>
					</li>
					<li><i class="fa fa-headphones"></i><b><span class class="phone">0(850) 302 54 67</span></b>
						<br>{{'technical-Support'|translate}}
					</li>
					<li><i class="icon-black-envelope"></i><a href="mailto:info@lios.com.tr">info@lios.com.tr</a></li>
				</ul>
			</div>
			<div class="col-sm-6 col-lg-4">
				<h3>{{'footer-Social'|translate}}</h3>
				<div class="h-decor"></div>
				<div class="footer-social d-md-none d-lg-block">
					<a href="{{global.socialLinks['fb']}}" target="blank" class="hovicon"><i class="icon-facebook-logo"></i></a>
					<a href="{{global.socialLinks['in']}}" target="blank" class="hovicon"><i class="icon-instagram"></i></a>
				</div>
			</div>
			<div class="col-lg-4 footer-col1">
				<h3>{{'footer-Lios'|translate}}</h3>
				<div class="h-decor"></div>
				<div class="row flex-column flex-md-row flex-lg-column mt-0">
					<div class="col-md col-lg-auto">
						<div class="footer-logo">
							<img src="./assets/images/footer-logo.png" alt="" class="img-fluid">
						</div>
						<div class="mt-2 mt-lg-0"></div>
					</div>
					<!-- <div class="col-md">
						<div class="footer-text mt-1 mt-lg-1">
							<p>{{'footer-Newsletter-Text'|translate}}</p>
							<form  class="footer-subscribe">
								<div class="input-group">
									<input name="subscribe_mail" type="text" class="form-control" placeholder="E-Mail"/>
									<span><i class="icon-black-envelope"></i></span>
								</div>
							</form>
						</div>
					</div> -->
				</div>
			</div>
		</div>
	</div>
	<div class="footer-bottom">
		<div class="container">
			<div class="row text-center text-md-left">
				<div class="col-sm">Copyright © {{ dateTime | date:'yyyy' }} <a href="#">Arbe Yazılım</a><span>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</span>
					All rights reserved.</div>
				<div class="col-sm-auto ml-auto"><span>{{'technical-Support'|translate}}&nbsp;&nbsp;&nbsp;</span><i class="fa fa-headphones text-white"></i>&nbsp;&nbsp;<b>0(850) 302 54 67</b></div>
			</div>
		</div>
	</div>
</div>
