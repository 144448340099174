<div class="page-content">
	<!--section-->
	<div class="section mt-0">
		<div class="contact-map">
			<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12245.983532597742!2d32.8176755!3d39.8855286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa71f79182e32a58c!2zQXJiZSBZYXrEsWzEsW0!5e0!3m2!1str!2str!4v1597677809207!5m2!1str!2str" allowfullscreen=""></iframe>		</div>
	</div>
	<!--//section-->
	<!--section-->
	<div class="section mt-0 bg-grey">
		<div class="container">
			<div class="row">
				<div class="col-md">
					<div class="title-wrap">
						<h5>{{'address'|translate}}</h5>
						<div class="h-decor"></div>
					</div>
					<ul class="icn-list-lg">
						<li><i class="icon-placeholder2"></i>Cevizlidere Mah. Cevizlidere Cad. Gökkuşağı Apt. No:42/11 
							<br>Çankaya/ANKARA
						</li>
					</ul>
				</div>
				<div class="col-md mt-3 mt-md-0">
					<div class="title-wrap">
						<h5>{{'contact-Information'|translate}}</h5>
						<div class="h-decor"></div>
					</div>
					<ul class="icn-list-lg">
						<li><i class="icon-telephone"></i><span class="text-nowrap"> 0(312) 472 63 79</span></li>
						<li><i class="fa fa-headphones"></i><span class="text-nowrap">0(850) 302 54 67 ({{'technical-Support'|translate}})</span></li>
						<li><i class="icon-black-envelope"></i><a href="mailto:info@lios.com.tr">info@lios.com.tr</a></li>
					</ul>
				</div>
				<div class="col-md mt-3 mt-md-0">
					<div class="title-wrap">
						<h5>{{'working-Time' | translate}}</h5>
						<div class="h-decor"></div>
					</div>
					<ul class="icn-list-lg">
						<li><i class="icon-clock"></i>
							<div>
								<div class="d-flex"><span>{{'day-1'|translate}} - {{'day-5'|translate}}</span><span class="theme-color"> 08:30 - 18:00 </span></div>
								<div class="d-flex"><span>{{'day-6'|translate}}</span><span class="theme-color">{{'close'|translate}}</span></div>
								<div class="d-flex"><span>{{'day-7'|translate}}</span><span class="theme-color">{{'close'|translate}}</span></div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
	<!--section-->
	<div class="section">
		<div class="container">
			<div class="row mb-3">
				<div class="col-md col-lg-5">
					<div class="pr-0 pr-lg-8">
						<div class="title-wrap">
							<h2>{{'get-Touch-Us' | translate}}</h2>
							<div class="h-decor"></div>
						</div>
						<div class="mt-2 mt-lg-4">
							<p>{{'get-Touch-Us-Text' | translate}}</p>
						</div>
						<!-- <div class="mt-2 mt-md-5"></div>
						<h5>Stay Connected</h5>
						<div class="content-social mt-15">
							<a href="https://www.facebook.com/" target="blank" class="hovicon"><i class="icon-facebook-logo"></i></a>
							<a href="https://www.twitter.com/" target="blank" class="hovicon"><i class="icon-twitter-logo"></i></a>
							<a href="https://plus.google.com/" target="blank" class="hovicon"><i class="icon-google-logo"></i></a>
							<a href="https://www.instagram.com/" target="blank" class="hovicon"><i class="icon-instagram"></i></a>
						</div> -->
					</div>
				</div>
				<div class="col-md col-lg-6 mt-4 mt-md-0">
					<form class="contact-form"  [formGroup]="form" (ngSubmit)="submitForm()" >
						<div class="messageForm animated flash" *ngIf="formMessage">
							<p class="successform">{{formMessage}}</p>
						</div>
						<div>
							<input type="text" class="form-control" formControlName="nameSurname" name="" [ngClass]="{ 'is-invalid': submitted && f.nameSurname.errors }" placeholder="{{'form-Name-Surname'|translate}} / {{'form-Institution-Name'|translate}}*">
							<div *ngIf="submitted && f.nameSurname.errors" class="errorform">
								<div *ngIf="f.nameSurname.errors.required">{{'form-Required-Name-Surname' | translate}}</div>
							</div>
						</div>
						<div class="mt-15">
							<input type="email" class="form-control" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="{{'form-Email'|translate}}*">
							<div *ngIf="submitted && f.email.errors" class="errorform">
								<div *ngIf="f.email.errors.required">{{'form-Required-Email' | translate}}</div>
								<div *ngIf="f.email.errors.email">{{'form-Required-Email-Valid' | translate}}</div>
							</div>
						</div>
						<div class="mt-15">
							<input type="text" class="form-control" formControlName="phone" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" placeholder="{{'form-Phone'|translate}}*">
							<div *ngIf="submitted && f.phone.errors" class="errorform">
								<div *ngIf="f.phone.errors.required">{{'form-Required-Phone' | translate}}</div>
							</div>
						</div>
						<div class="mt-15">
							<textarea class="form-control" formControlName="message" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" placeholder="{{'form-Message'|translate}}*"></textarea>
							<div *ngIf="submitted && f.message.errors" class="errorform">
								<div *ngIf="f.message.errors.required">{{'form-Required-Message' | translate}}</div>
							</div>
						</div>
						<div class="mt-3">
							<button class="btn btn-hover-fill"><i class="icon-right-arrow"></i><span>{{'form-Send-Message'|translate}}</span><i class="icon-right-arrow"></i></button>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
</div>