<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'biochemistry-Laboratories-Title' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<div class="h-sub theme-color">Laboratory Information Operating System</div>
				<h1>{{'biochemistry-Laboratories-Title' | translate}}</h1>
				<div class="h-decor"></div>
			</div>
		</div>
		<div class="container">
			<div class="row">
                <div class="col-md-12 d-md-flex">
                    <div class="col-md-6">
                        <div [innerHTML]="'biochemistry-Laboratories-Define-Text-1' | translate"></div>
                    </div>
                    <div class="col-md-6">
                        <div class="text-2" [innerHTML]="'biochemistry-Laboratories-Define-Text-2' | translate"></div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 text-center text-lg-left pr-md-4">
					<img src="./assets/images/content/index-img-01.jpg" class="w-100" alt="">
					<div class="row mt-3">
						<div class="col-6">
							<img src="./assets/images/content/services-03.jpg" class="w-100" alt="">
						</div>
						<div class="col-6">
							<img src="./assets/images/content/services-06.jpg" class="w-100" alt="">
						</div>
					</div>
                </div> -->
			</div>
		</div>
		<div class="">
			<div class="mt-3 p-2 bg-theme-color2">
				<div class="text-center">
					<h1 class="text-white">{{'home-Section-2-Title' | translate}}</h1>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
</div>