import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {SendFormService} from '../../services/forms/send-form.service';
import { Globals } from '../../globals';

@Component({
  selector: 'app-quick-links',
  templateUrl: './quick-links.component.html',
  styleUrls: ['./quick-links.component.css']
})
export class QuickLinksComponent implements OnInit {

  private SERVER_URL = "https://Server.lios.com.tr:85/api/Arbe/SendDemoRequest";
  submitted = false;
  form: FormGroup;
  formMessage = "";
  isHaveAnnouncement = false;
  constructor(public fb: FormBuilder,private sendFormService:SendFormService,public globals :Globals) { 
    this.formMessage ="";
  }
  ngOnInit(): void {  
    this.form = this.fb.group({
      nameSurname: ['',Validators.required],
      city: ['',Validators.required],
      district: ['Çankaya'],
      institution: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      phone1: ['',Validators.required],
      phone2: ['',Validators.required],
      program: ['LİOS'],
      notes: ['',Validators.required]
    });
    this.isHaveAnnouncement = this.globals.announcementList.length>0;
  }
  get f() { return this.form.controls; }
  submitForm() {
    this.submitted = true;
    // valid değilse  
    if (this.form.invalid) {
        return;
    }

    this.sendFormService.sendForm(this.SERVER_URL,this.form.value).subscribe((response=>{
      if(response && response["Message"])
      {
        this.submitted = false;
        response["Code"] == "0000" ? this.reset():null;
        this.formMessage = response["Message"];
      }
      else{
        this.formMessage = "Mesaj gönderilirken hata meydana geldi.";
      }
    }));

  }
  reset() {
    this.form.reset();
  }
  
}