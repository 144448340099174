<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'announcements' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<h1>{{'announcements' | translate}}</h1>
				<div class="h-decor"></div>
			</div>
		</div>
		<div class="container">
			<div class="blog-isotope">
				<div class="blog-post" *ngFor="let announcement of 'announcements-List' | translate">
					<div class="post-image">
						<a routerLink="/Duyurular/{{announcement.id}}"><img [src]="announcement.img" alt=""></a>
					</div>
					<div class="blog-post-info">
						<div class="post-date">{{announcement.date.substring(0, 2)}}<span>{{announcement.date.substring(3,5)}}</span></div>
						<div>
							<h2 class="post-title"><a routerLink="/Duyurular/{{announcement.id}}">{{announcement.title}}</a></h2>
							<div class="post-meta">
								<div class="post-meta-author"><i class="theme-color">LIOS</i></div>
								<!-- <div class="post-meta-social">
									<a href="#"><i class="icon-facebook-logo"></i></a>
									<a href="#"><i class="icon-twitter-logo"></i></a>
									<a href="#"><i class="icon-google-logo"></i></a>
									<a href="#"><i class="icon-instagram"></i></a>
								</div> -->
							</div>
						</div>
					</div>
					<div class="post-teaser" [innerHTML]="announcement.text.substring(0, 250)"></div>
					<div class="mt-2"><a routerLink="/Duyurular/{{announcement.id}}" class="btn btn-sm btn-hover-fill"><i class="icon-right-arrow"></i><span> {{'read-More' | translate}}</span><i class="icon-right-arrow"></i></a></div>
                </div>
              
            </div>
            <div *ngIf="!isHaveAnnouncement" class="pb-5">
                <h1 class="text-center">{{'not-announcements'|translate}}</h1>
            </div>
			<div class="clearfix"></div>
		</div>
	</div>
</div>