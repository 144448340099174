<div class="section bg-bottom bg-right bg-norepeat bg-md-none section-top-padding pt-0" style="background-image: url(./assets/images/bg-department.png)">
    <div class="container">
        <div class="row mt-lg-4">
            <div class="col-md-12">
                <div class="department-carousel2">
                    <div class="department-item">
                        <h2 class="text-center">Lios<br><span class="theme-color">Laboratory Information Operating System</span></h2>
                        <p [innerHTML]="'home-Section-1-Text-1'|translate"></p>
                        <p [innerHTML]="'home-Section-1-Text-2'|translate"></p>
                        <p [innerHTML]="'home-Section-1-Text-3'|translate"></p>
                        <!-- <div class="col-md-12 d-flex">
                            <div class="col-md-6">
                                <ul class="marker-list-md">
                                    <li *ngFor="let spec of 'home-Section-1-spec1' | translate">{{spec.item}}</li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul class="marker-list-md">
                                    <li *ngFor="let spec of 'home-Section-1-spec2' | translate">{{spec.item}}</li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <!-- <div class="department-tabs2">
                    <div class="department-tab2">
                        <div class="department-tab2-number text-center"><i class="icon-arrow-right"></i></div>
                        <div class="department-tab2-text">Yatay Onay Ekranı</div>
                        <div class="department-tab2-decor"></div>
                        <div class="">Yatay Onay ekranı bulunmaktadır. Test grup bazlı olarak toplu bir şekilde sonuç onaylama işlemi yapılabilir.</div>
                    </div>
                    <div class="department-tab2">
                        <div class="department-tab2-number text-center"><i class="icon-black-envelope"></i></div>
                        <div class="department-tab2-text">Sms Modülü</div>
                        <div class="department-tab2-decor"></div>
                        <div class="">SMS gönderim özelliği ile hasta ve hekim bazlı bilgilendirmeler yapılabilmetedir.</div>
                    </div>
                </div> -->
                <div class="col-md-12 p-0 text-center">
					<div class="service-card mw-100 mt-3">
						<h5 class="service-card-name pt-3">
                           Arbe / Lios
						</h5>
                        <p>{{'arbe-Lios-Define'|translate}}</p>
                        <div><a href="https://www.arbeyazilim.com.tr/" target="_blank" class="btn"><i class="icon-right-arrow"></i><span>Arbe</span><i class="icon-right-arrow"></i></a></div>
					</div>
				</div>
            </div>
        </div>
    </div>
</div>