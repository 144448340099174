import { Component, OnInit } from '@angular/core';
import { Globals } from '../../globals';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.css']
})
export class AnnouncementsComponent implements OnInit {
  isHaveAnnouncement = false;
  constructor(public globals :Globals) { }

  ngOnInit(): void {
    this.isHaveAnnouncement = this.globals.announcementList.length>0;
  }

}
