import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SendFormService {

  constructor(private httpClient:HttpClient) {}
  public sendForm(url,body)
  {
     return this.httpClient.post(url,body);
  }
}
