<div class="section bg-grey mt-md-0">
    <div class="container">
        <div class="title-wrap text-center">
            <h2 class="h1 double-title double-title--white double-title--center double-title--vcenter" data-title="LIOS">
                <span>LIOS<span class="clearfix d-sm-none"></span> Laboratory Information Operating System</span></h2>
            <div class="h-decor"></div>
        </div>
        <div class="nav nav-pills-icons" role="tablist">
            <a class="nav-link active" data-toggle="pill" href="#tab-A" role="tab"><i class="icon-molecular"></i><span>{{'biochemistry-Laboratories-Title' | translate}}</span></a>
            <a class="nav-link" data-toggle="pill" href="#tab-B" role="tab"><i class="icon-testtube2"></i><span>{{'genetic-Laboratories-Title' | translate}}</span></a>
            <a class="nav-link" data-toggle="pill" href="#tab-C" role="tab"><i class="icon-micro"></i><span>{{'pathology-Laboratories-Title' | translate}}</span></a>
            <a class="nav-link" data-toggle="pill" href="#tab-D" role="tab">
<!--                 <i class="fa fa-check-square"></i>
 -->                <svg class='svg-icon' version="1.1" width="62px" height="62px" viewBox="0 0 73.625 73.627" enable-background="new 0 0 62 62"
                    xml:space="preserve">
                    <g>
                        <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M7.967,53.012c2.166,0.712,4.245,1.396,6.406,2.106
                		c0.695-2.103,1.379-4.173,2.131-6.447c-0.518,0.241-0.865,0.407-1.215,0.565c-0.885,0.4-1.585,0.14-1.892-0.771
                		c-1.655-4.916-1.518-9.79,0.405-14.607c0.295-0.739,0.943-1.048,1.588-0.816c0.679,0.245,0.993,0.935,0.689,1.699
                		c-0.995,2.505-1.503,5.097-1.337,7.788c0.079,1.29,0.326,2.569,0.512,3.967c0.946-0.472,1.747-0.854,2.529-1.268
                		c0.564-0.299,1.106-0.416,1.631,0.046c0.496,0.436,0.47,0.98,0.283,1.55c-1.104,3.343-2.2,6.689-3.312,10.03
                		c-0.303,0.912-0.88,1.223-1.781,0.93c-3.396-1.105-6.792-2.21-10.166-3.381c-0.396-0.137-0.789-0.637-0.935-1.055
                		c-0.197-0.566,0.192-1.014,0.721-1.283c0.724-0.368,1.45-0.733,2.082-1.053c-0.392-1.875-0.856-3.654-1.124-5.462
                		c-0.755-5.099-0.039-10.023,1.926-14.78c0.125-0.304,0.225-0.875,0.08-0.985c-0.415-0.316-0.836-0.794-1.534-0.327
                		c-0.811,0.543-1.423,0.337-1.919-0.502c-0.767-1.297-1.519-2.602-2.258-3.915c-0.477-0.847-0.316-1.407,0.51-1.934
                		c0.808-0.516,0.797-1.513-0.021-2.042c-0.789-0.51-0.962-1.08-0.515-1.877c0.75-1.335,1.518-2.662,2.299-3.98
                		c0.492-0.83,1.056-0.956,1.939-0.49c0.8,0.422,1.683-0.084,1.734-0.994c0.058-1.03,0.463-1.438,1.47-1.444
                		c1.482-0.009,2.965-0.011,4.448,0c0.966,0.007,1.382,0.422,1.442,1.396c0.06,0.963,0.924,1.469,1.76,1.03
                		c0.87-0.457,1.42-0.313,1.932,0.525c0.344,0.564,0.667,1.141,1.022,1.749c8.013-4.181,16.202-4.582,24.572-1.296
                		c0.338-2,0.338-2,3.087-2.222c0.234-0.549,0.504-1.099,0.691-1.675c0.055-0.17-0.06-0.488-0.204-0.618
                		c-2.055-1.844-1.336-2.267,0.08-3.646c0.788-0.767,1.551-1.558,2.336-2.327c0.687-0.671,1.424-0.755,1.98-0.016
                		c0.802,1.066,1.634,1.158,2.681,0.48c0.126-0.082,0.281-0.119,0.482-0.202c0-0.541-0.014-1.099,0.003-1.657
                		c0.026-0.86,0.479-1.325,1.34-1.334c1.559-0.017,3.119-0.018,4.678,0c0.858,0.01,1.301,0.478,1.339,1.339
                		c0.001,0.026,0,0.051,0,0.077c0.047,1.702,0.045,1.708,1.693,2.266c0.151,0.051,0.361-0.017,0.524-0.079
                		c0.109-0.041,0.185-0.17,0.275-0.261c1.55-1.537,1.732-1.538,3.255-0.015c0.849,0.85,1.705,1.692,2.545,2.551
                		c0.699,0.714,0.693,1.342,0.012,2.062c-1.19,1.258-1.199,1.263-0.365,2.801c0.08,0.149,0.333,0.268,0.518,0.284
                		c0.456,0.042,0.92-0.007,1.378,0.022c0.722,0.047,1.213,0.571,1.202,1.238c-0.012,0.637-0.484,1.164-1.167,1.191
                		c-0.944,0.038-1.891,0.029-2.836,0.003c-0.609-0.016-0.947-0.402-1.163-0.952c-0.399-1.017-0.812-2.03-1.256-3.027
                		c-0.259-0.583-0.234-1.094,0.218-1.546c0.324-0.325,0.68-0.619,1.075-0.975c-0.668-0.584-1.257-1.099-1.984-1.734
                		c-0.173,0.204-0.44,0.538-0.729,0.853c-0.523,0.571-1.095,0.598-1.793,0.256c-0.911-0.446-1.864-0.812-2.818-1.162
                		c-0.687-0.251-1.06-0.67-1.039-1.417c0.011-0.406,0.002-0.813,0.002-1.272c-0.814,0-1.589,0-2.456,0c0,0.454,0,0.906,0,1.357
                		c0,0.678-0.356,1.071-0.973,1.311c-0.995,0.385-1.989,0.778-2.958,1.224c-0.642,0.295-1.18,0.264-1.669-0.249
                		c-0.299-0.313-0.586-0.637-0.912-0.992c-0.603,0.641-1.139,1.209-1.77,1.879c0.285,0.261,0.626,0.559,0.951,0.874
                		c0.482,0.467,0.532,0.992,0.257,1.606c-0.446,0.998-0.859,2.01-1.256,3.027c-0.243,0.62-0.651,0.956-1.326,0.951
                		c-0.431-0.003-0.862-0.001-1.302-0.001c-0.206,0.654-0.059,1.025,0.55,1.377c4.602,2.659,8.154,6.355,10.708,11.014
                		c0.64,1.167,0.637,1.169,2.154,1.065c0-0.413,0.012-0.837-0.003-1.26c-0.025-0.781,0.363-1.206,1.088-1.458
                		c0.911-0.317,1.813-0.677,2.682-1.093c0.896-0.43,1.255-0.421,1.972,0.291c0.253,0.252,0.48,0.53,0.711,0.788
                		c0.579-0.63,1.105-1.203,1.776-1.933c-0.18-0.151-0.538-0.425-0.869-0.73c-0.52-0.479-0.562-1.021-0.266-1.665
                		c0.445-0.969,0.844-1.961,1.226-2.958c0.237-0.618,0.621-0.982,1.301-0.983c0.843-0.001,1.687-0.014,2.53,0.003
                		c0.825,0.017,1.363,0.536,1.343,1.255c-0.02,0.689-0.541,1.16-1.332,1.192c-0.281,0.011-0.562,0.002-0.844,0.002
                		c-0.254,0-0.509,0-0.796,0c-0.243,0.569-0.499,1.12-0.707,1.687c-0.054,0.148,0.004,0.365,0.072,0.523
                		c0.057,0.131,0.206,0.222,0.314,0.331c1.477,1.481,1.475,1.699-0.029,3.201c-0.868,0.867-1.727,1.744-2.606,2.6
                		c-0.65,0.632-1.389,0.703-1.927,0.001c-0.792-1.034-1.613-1.215-2.677-0.508c-0.142,0.095-0.319,0.137-0.465,0.196
                		c-0.046,2.82,0.222,3.024-2.958,3.056c0.274,0.958,0.566,1.895,0.81,2.844c1.251,4.887,1.163,9.759-0.191,14.612
                		c-0.278,1-0.833,1.413-1.615,1.211c-0.743-0.191-0.996-0.866-0.743-1.877c2.479-9.904,0.262-18.631-7.127-25.67
                		c-7.114-6.776-15.733-8.81-25.123-5.95c-9.41,2.865-15.305,9.396-17.663,18.963c-1.197,4.858-0.792,9.688,0.895,14.402
                		C9.396,51.905,9.336,52.095,7.967,53.012z M9.944,25.61c-1.731-0.812-2.7-2.069-2.463-4.008c0.185-1.514,1.063-2.558,2.5-2.997
                		c1.983-0.605,3.45,0.231,4.491,1.907c0.987-0.765,1.929-1.496,2.913-2.259c-0.179-0.306-0.34-0.58-0.532-0.908
                		c-0.811,0.599-1.473,0.374-2.126-0.179c-0.307-0.259-0.675-0.482-1.052-0.615c-0.853-0.299-1.458-0.754-1.347-1.786
                		c-0.836,0-1.594,0-2.433,0c0.106,1.047-0.542,1.5-1.409,1.806c-0.331,0.117-0.665,0.3-0.921,0.536
                		c-0.677,0.622-1.39,0.813-2.234,0.271c-0.41,0.71-0.79,1.368-1.211,2.097c1.02,0.407,1.03,1.222,0.899,2.113
                		c-0.052,0.35-0.043,0.718,0.004,1.069c0.118,0.864,0.12,1.663-0.917,2.034c0.438,0.756,0.828,1.429,1.248,2.153
                		c1.463-1.102,2.159,0.621,3.32,0.766C9.083,26.966,9.502,26.306,9.944,25.61z M11.068,20.872c-0.665,0.021-1.188,0.583-1.169,1.258
                		c0.019,0.671,0.579,1.206,1.243,1.185c0.665-0.021,1.187-0.582,1.169-1.258C12.292,21.384,11.733,20.851,11.068,20.872z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.471,62.585c0,1.673,0,3.248,0,4.909c0.329,0,0.627,0,0.925,0
                		c9.79,0,19.581,0.068,29.37-0.019c10.309-0.092,18.017-4.717,23.11-13.687c0.038-0.066,0.072-0.135,0.11-0.201
                		c0.431-0.739,1.083-0.985,1.721-0.648c0.636,0.335,0.875,1.062,0.438,1.773c-0.975,1.584-2.021,3.126-3.044,4.681
                		c-0.139,0.211-0.311,0.399-0.53,0.676c3.096,0,6.111,0,9.222,0c0-0.722,0.01-1.454-0.003-2.187
                		c-0.011-0.623,0.203-1.103,0.805-1.343c0.583-0.233,1.037,0.01,1.444,0.418c2.349,2.351,4.704,4.695,7.046,7.052
                		c0.719,0.723,0.721,1.336,0.003,2.059c-2.342,2.357-4.697,4.702-7.047,7.051c-0.429,0.43-0.908,0.667-1.516,0.389
                		c-0.562-0.257-0.745-0.725-0.735-1.312c0.012-0.713,0.003-1.426,0.003-2.248c-0.359,0-0.68,0-1.001,0c-20.296,0-40.591,0-60.887,0
                		c-1.617,0-1.919-0.301-1.919-1.916c0-2.097-0.002-4.193,0.001-6.289c0.002-1.204,0.406-1.61,1.609-1.61
                		c10.429-0.002,20.858,0.007,31.287-0.004c8.964-0.01,16.314-5.921,18.25-14.652c2.207-9.958-4.703-20.233-14.772-21.847
                		c-7.164-1.148-13.072,1.163-17.7,6.749c-0.065,0.079-0.125,0.162-0.192,0.238c-0.523,0.593-1.219,0.71-1.763,0.298
                		c-0.575-0.437-0.646-1.176-0.144-1.833c1.759-2.305,3.886-4.202,6.432-5.593c12.909-7.05,28.648,0.807,30.788,15.358
                		C55.48,50.4,46.902,61.346,35.273,62.454c-0.888,0.085-1.785,0.125-2.678,0.126c-9.713,0.009-19.426,0.006-29.14,0.006
                		C3.152,62.585,2.849,62.585,2.471,62.585z M66.312,60.916c-0.248,1.523-0.424,1.669-2.108,1.669c-3.805,0-7.611-0.012-11.417,0.02
                		c-0.386,0.003-0.82,0.199-1.146,0.427c-1.232,0.863-2.398,1.822-3.648,2.656c-0.961,0.642-2.01,1.151-3.172,1.806
                		c0.437,0,0.707,0,0.978,0c6.105,0,12.21,0,18.314,0c0.306,0,0.614-0.016,0.919,0.007c0.71,0.055,1.111,0.453,1.208,1.155
                		c0.028,0.204,0.04,0.409,0.046,0.472c1.391-1.38,2.797-2.776,4.132-4.101C69.073,63.68,67.675,62.281,66.312,60.916z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M44.121,34.925c-0.596-0.57-1.164-1.114-1.768-1.691
                		c-0.147,0.14-0.349,0.322-0.542,0.515c-3.273,3.271-6.543,6.543-9.817,9.813c-0.95,0.949-1.541,0.933-2.415-0.08
                		c-1.502-1.741-2.995-3.49-4.543-5.298c-0.614,0.522-1.208,1.028-1.861,1.585c0.267,0.317,0.505,0.604,0.747,0.887
                		c1.862,2.173,3.723,4.349,5.589,6.52c0.768,0.895,1.416,0.924,2.256,0.09c1.325-1.314,2.637-2.642,3.961-3.956
                		c0.67-0.666,1.423-0.726,1.946-0.179c0.519,0.542,0.464,1.222-0.194,1.888c-1.384,1.4-2.763,2.805-4.179,4.171
                		c-1.664,1.606-3.966,1.514-5.486-0.233c-2.4-2.757-4.769-5.54-7.141-8.321c-0.695-0.814-0.638-1.393,0.178-2.103
                		c1.1-0.957,2.207-1.903,3.318-2.845c0.791-0.67,1.407-0.623,2.106,0.182c1.374,1.583,2.732,3.179,4.098,4.768
                		c0.148,0.172,0.306,0.336,0.669,0.732c0.167-0.286,0.265-0.572,0.457-0.765c3.226-3.245,6.463-6.478,9.7-9.712
                		c0.97-0.969,1.482-0.968,2.444-0.008c0.978,0.975,1.958,1.946,2.926,2.93c0.737,0.749,0.741,1.366-0.005,2.118
                		c-1.746,1.761-3.5,3.513-5.263,5.257c-0.665,0.659-1.343,0.706-1.885,0.178c-0.542-0.529-0.476-1.272,0.196-1.946
                		C41.112,37.919,42.617,36.425,44.121,34.925z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M52.751,17.152c0.013-3.378,2.794-6.122,6.183-6.101
                		c3.377,0.021,6.111,2.81,6.082,6.202c-0.029,3.341-2.794,6.069-6.145,6.063C55.492,23.312,52.738,20.537,52.751,17.152z
                		 M55.205,17.118c-0.03,2.041,1.619,3.732,3.651,3.746c2.005,0.013,3.676-1.617,3.706-3.614c0.031-2.04-1.618-3.731-3.651-3.744
                		C56.904,13.493,55.235,15.121,55.205,17.118z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.575,4.324c0,0.945,0.009,1.891-0.003,2.837
                		c-0.012,0.96-0.458,1.423-1.403,1.43c-1.917,0.013-3.833,0.012-5.75,0C0.457,8.586-0.004,8.136-0.011,7.19
                		c-0.012-1.917-0.011-3.834,0-5.751C-0.006,0.478,0.443,0.016,1.39,0.01c1.917-0.013,3.834-0.012,5.751-0.001
                		c0.988,0.006,1.422,0.462,1.432,1.478C8.582,2.433,8.575,3.378,8.575,4.324z M2.494,2.494c0,1.245,0,2.427,0,3.586
                		c1.238,0,2.42,0,3.585,0c0-1.226,0-2.39,0-3.586C4.875,2.494,3.711,2.494,2.494,2.494z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.98,4.377c0,0.946,0.012,1.892-0.004,2.837
                		C26.96,8.12,26.504,8.583,25.598,8.59c-1.942,0.015-3.885,0.015-5.827,0c-0.906-0.007-1.369-0.471-1.375-1.377
                		c-0.015-1.942-0.015-3.885,0-5.828c0.006-0.907,0.468-1.369,1.376-1.375c1.942-0.014,3.885-0.014,5.827,0
                		c0.906,0.006,1.361,0.469,1.377,1.376C26.993,2.384,26.98,3.38,26.98,4.377z M24.491,2.502c-1.254,0-2.418,0-3.596,0
                		c0,1.225,0,2.407,0,3.583c1.232,0,2.414,0,3.596,0C24.491,4.865,24.491,3.702,24.491,2.502z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M45.386,4.231c0,0.997,0.014,1.994-0.004,2.99
                		c-0.016,0.902-0.476,1.362-1.385,1.369c-1.942,0.014-3.885,0.015-5.828,0c-0.901-0.007-1.361-0.476-1.367-1.385
                		c-0.014-1.942-0.015-3.885,0-5.828c0.007-0.902,0.473-1.361,1.383-1.367c1.943-0.014,3.885-0.014,5.828,0
                		c0.902,0.007,1.354,0.473,1.369,1.384C45.398,2.34,45.386,3.286,45.386,4.231z M39.313,2.488c0,1.266,0,2.448,0,3.611
                		c1.226,0,2.39,0,3.57,0c0-1.224,0-2.406,0-3.611C41.672,2.488,40.508,2.488,39.313,2.488z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.663,4.299c-0.438-0.329-0.774-0.561-1.088-0.82
                		c-0.608-0.503-0.714-1.196-0.287-1.756c0.416-0.543,1.087-0.661,1.703-0.229c0.836,0.586,1.66,1.193,2.45,1.84
                		c0.695,0.57,0.675,1.395-0.036,1.968c-0.775,0.625-1.576,1.219-2.39,1.792c-0.64,0.45-1.346,0.323-1.756-0.257
                		c-0.39-0.553-0.283-1.214,0.297-1.697C11.884,4.866,12.236,4.621,12.663,4.299z" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.063,4.304c-0.417-0.316-0.766-0.562-1.093-0.834
                		c-0.604-0.503-0.703-1.201-0.268-1.757c0.421-0.538,1.087-0.646,1.706-0.211c0.814,0.572,1.616,1.166,2.392,1.79
                		c0.738,0.593,0.737,1.428,0,2.02c-0.776,0.624-1.577,1.218-2.392,1.791c-0.615,0.432-1.284,0.323-1.706-0.215
                		c-0.435-0.555-0.335-1.256,0.269-1.756C30.3,4.859,30.651,4.615,31.063,4.304z" />
                    </g>
                </svg>

                
                <span>{{'lios-Approval-Support-System-Title' | translate}}</span></a>
        </div>
        <div id="tab-content" class="tab-content mt-2 mt-sm-4">
            <div id="tab-A" class="tab-pane fade show active" role="tabpanel">
                <div class="tab-bg"><img src="./assets/images/content/bg-map.png" alt=""></div>
                <div class="row">
                    <div class="col-md-6 h-100 mb-2 mb-md-0">
                        <img src="./assets/images/content/index-img-01.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6">
                        <div class="pt-xl-1">
                            <h3>{{'biochemistry-Laboratories-Title' | translate}}</h3>
                            <div [innerHTML]="'biochemistry-Laboratories-Define-Text-1' | translate"></div>
                            <a routerLink="/Klinik-Biyokimya-Laboratuvarlari" class="btn mt-3" ><i class="icon-right-arrow"></i><span>{{'more-Information' | translate}}</span><i class="icon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tab-B" class="tab-pane fade" role="tabpanel">
                <div class="tab-bg"><img src="./assets/images/content/bg-map.png" alt=""></div>
                <div class="row">
                    <div class="col-md-6 h-100 mb-2 mb-md-0">
                        <img src="./assets/images/content/index-img-01-2.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6">
                        <div class="pt-xl-1">
                            <h3>{{'genetic-Laboratories-Title' | translate}}</h3>
                            <div [innerHTML]="'genetic-Laboratories-Define-Text-1' | translate"></div>
                            <div [innerHTML]="('genetic-Laboratories-Define-Text-2' | translate).split('</ul>').shift()"></div>
                            <a routerLink="/Genetik-Laboratuvarlari" class="btn mt-3" ><i class="icon-right-arrow"></i><span>{{'more-Information' | translate}}</span><i class="icon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tab-C" class="tab-pane fade" role="tabpanel">
                <div class="tab-bg"><img src="./assets/images/content/bg-map.png" alt=""></div>
                <div class="row">
                    <div class="col-md-6 h-100 mb-2 mb-md-0">
                        <img src="./assets/images/content/index-img-01-3.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6">
                        <div class="pt-xl-1">
                            <h3>{{'pathology-Laboratories-Title' | translate}}</h3>
                            <div [innerHTML]="'pathology-Laboratories-Define-Text-1' | translate">
                                
                            </div>
                            <div class="pt-2" [innerHTML]="('pathology-Laboratories-Define-Text-2' | translate).split('</p>').shift()"></div>
                            <a routerLink="/Patoloji-Laboratuvarlari" class="btn mt-3" ><i class="icon-right-arrow"></i><span>{{'more-Information' | translate}}</span><i class="icon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div id="tab-D" class="tab-pane fade" role="tabpanel">
                <div class="tab-bg"><img src="./assets/images/content/bg-map.png" alt=""></div>
                <div class="row">
                    <div class="col-md-6 h-100 mb-2 mb-md-0">
                        <img src="./assets/images/content/index-img-01-4.jpg" alt="" class="img-fluid">
                    </div>
                    <div class="col-md-6">
                        <div class="pt-xl-1">
                            <h3>{{'lios-Approval-Support-System-Title' | translate}}</h3>
                            <div [innerHTML]="'lios-Approval-Support-System-Define-Text-1' | translate"></div>
                            <a routerLink="/Lios-Onay-Destek-Sistemi" class="btn mt-3"  ><i class="icon-right-arrow"></i><span>{{'more-Information' | translate}}</span><i class="icon-right-arrow"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>