<div class="section mt-0">
    <app-quick-links></app-quick-links>
    <div id="mainSliderWrapper">
        <div class="loading-content">
            <div class="loader-dna">
                <column>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                </column>
                <column>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                    <dash></dash>
                </column>
                <column>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                    <dot></dot>
                </column>
            </div>
        </div>
        <div class="main-slider mb-0 arrows-white arrows-bottom" id="mainSlider" data-slick='{"arrows": false, "dots": true}'>
            <div class="slide">
                <div class="img--holder" data-bg="./assets/images/content/slider/slide-01.jpg"></div>
                <div class="slide-content center">
                    <div class="vert-wrap container">
                        <div class="vert">
                            <div class="container">
                                <div class="slide-txt1 text-no-uppercase" data-animation="fadeInDown" data-animation-delay="1s">Gelişim ve İlerleme
                                    Değişiklik ve yenilik taleplerine hızlı ve akılcıl çözümler
                                </div>
                                <div class="slide-txt2 text-no-uppercase" data-animation="fadeInUp" data-animation-delay="1.5s">LIOS alanında uzman doktorlardan oluşan danışman ekibiyle, sistemin kullanıldığı tüm laboratuvarlardan gelen değişiklik ve yenilik taleplerine hızlı ve akılcıl çözümler üreterek her geçen gün daha da gelişmektedir.</div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide">
                <div class="img--holder" data-bg="./assets/images/content/slider/slide-02.jpg"></div>
                <div class="slide-content center">
                    <div class="vert-wrap container">
                        <div class="vert">
                            <div class="container">
                                <div class="slide-txt1 text-no-uppercase" data-animation="fadeInDown" data-animation-delay="1s">Başarı Hikayemiz...
                                    <br>Sağlık sektörü bilgi yönetim sistemlerinde uzun yıllar tecrübe
                                </div>
                                <div class="slide-txt2 text-no-uppercase" data-animation="fadeInUp" data-animation-delay="1.5s">LIOS tıbbi tahlil laboratuvarları için özel olarak, sağlık sektörü için hazırlanmış bilgi yönetim sistemleri yazılımlarında, hastane ve tıbbi tahlil laboratuvarlarında uzun yıllar hizmet vermiş tecrübeli ekibimiz tarafından üretildi.</div>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="slide">
                <div class="img--holder" data-bg="./assets/images/content/slider/slide-03.jpg"></div>
                <div class="slide-content center">
                    <div class="vert-wrap container">
                        <div class="vert">
                            <div class="container">
                                <div class="slide-txt1 text-no-uppercase" data-animation="fadeInDown" data-animation-delay="1s">Genç, Hevesli, Analitik düşünebilen, Öğrenmeye ve ilerlemeye açık
                                    <br>Ekibe Sahibiz
                                </div>
                                <div class="slide-txt1"></div>
                                <div class="slide-txt2 text-no-uppercase" data-animation="fadeInUp" data-animation-delay="3.5s">Tecrübeli proje ekibimiz, hızlı, dinamik teknik destek ekibimiz ilk günkü heyecanımızla çalışmaya ve ilerlemeye devam ediyoruz.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>