import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import {SendFormService} from '../../services/forms/send-form.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})

export class ContactComponent implements OnInit {
  private SERVER_URL = "https://Server.lios.com.tr:85/api/Arbe/SendContactMessage";
  submitted = false;
  form: FormGroup;
  formMessage = "";
  constructor(public fb: FormBuilder,private sendFormService:SendFormService) { 
    this.formMessage ="";
  }
  ngOnInit(): void {  
    this.form = this.fb.group({
      nameSurname: ['',Validators.required],
      email: ['',[Validators.required,Validators.email]],
      phone: ['',Validators.required],
      message: ['',Validators.required]
    });
    
  }
  // Form alanlarına erişim
  get f() { return this.form.controls; }

  submitForm() {
    this.submitted = true;
    // valid değilse  
    if (this.form.invalid) {
        return;
    }

    this.sendFormService.sendForm(this.SERVER_URL,this.form.value).subscribe((response=>{
      if(response && response["Message"])
      {
        this.formMessage = response["Message"];
        response["Code"] == "0000" ? this.reset():null;
        this.submitted = false;
      }
      else{
        this.formMessage = "Mesaj gönderilirken hata meydana geldi.";
      }
    }));

  }
  reset() {
    this.form.reset();
  }
}
