<div class="page-content">
	<!--section-->
	<app-breadcrumbs breadCrumbName	="{{'lios-Approval-Support-System-Title' | translate}}"></app-breadcrumbs>
	<!--//section-->
	<!--section-->
	<div class="section page-content-first">
		<div class="container">
			<div class="text-center mb-2  mb-md-3 mb-lg-4">
				<div class="h-sub theme-color">Laboratory Information Operating System</div>
				<h1>{{'lios-Approval-Support-System-Title' | translate}}</h1>
				<div class="h-decor"></div>
			</div>
		</div>
		<div class="container">
			<div class="row">
                <div class="col-md-12">
                    <div [innerHTML]="'lios-Approval-Support-System-Define-Text-1' | translate"></div>
                </div>
                
                <div class="col-md-12 pt-3 " [innerHTML]="'lios-Approval-Support-System-Define-Text-2' | translate"></div>
			</div>
		</div>
		<div class="">
			<div class="mt-3 p-2 bg-theme-color2">
				<div class="text-center">
					<h1 class="text-white">{{'home-Section-2-Title' | translate}}</h1>
				</div>
			</div>
		</div>
	</div>
	<!--//section-->
</div>