<div class="section mt-0">
    <app-quick-links></app-quick-links>
    <owl-carousel-o [options]="customOptions" class="main-slider  mb-0 arrows-white arrows-bottom slick-initialized" id="mainSlider">
        <ng-template carouselSlide class="slide">
            <img class="img--holder"  src="./assets/images/content/slider/slide-01.jpg">
            <div class="slide-content center">
                <div class="vert-wrap container">
                    <div class="vert">
                        <div class="container">
                            <div class="slide-txt1 text-no-uppercase" [innerHTML]="'slider-1-Title' | translate">
                            </div>
                            <div class="slide-txt2 text-no-uppercase" [innerHTML]="'slider-1-Text' | translate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide class="slide"> 
            <img class="img--holder" src="./assets/images/content/slider/slide-02.jpg">
            <div class="slide-content center">
                <div class="vert-wrap container">
                    <div class="vert">
                        <div class="container">
                            <div class="slide-txt1 text-no-uppercase" [innerHTML]="'slider-2-Title' | translate">
                            </div>
                            <div class="slide-txt2 text-no-uppercase" [innerHTML]="'slider-2-Text' | translate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide class="slide">
            <img class="img--holder" src="./assets/images/content/slider/slide-03.jpg">
            <div class="slide-content center">
                <div class="vert-wrap container">
                    <div class="vert">
                        <div class="container">
                            <div class="slide-txt1 text-no-uppercase" [innerHTML]="'slider-3-Title' | translate">
                            </div>
                            <div class="slide-txt2 text-no-uppercase" [innerHTML]="'slider-3-Text' | translate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>    
    <!-- <owl-carousel-o [options]="customOptions" class="main-slider mb-0 arrows-white arrows-bottom" id="mainSlider">
    <ng-container *ngFor="let slide of 'slider' | translate" class="slide">
        <ng-template carouselSlide [id]="slide.id">
            <img class="img--holder" src="{{slide.src}}" [alt]="slide.alt" [title]="slide.title">
            <div class="slide-content center">
                <div class="vert-wrap container">
                    <div class="vert">
                        <div class="container">
                            <div class="slide-txt1 text-no-uppercase" [innerHTML]="slide.title">
                            </div>
                            <div class="slide-txt2 text-no-uppercase" [innerHTML]="slide.text"></div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </ng-container>
    </owl-carousel-o> -->
</div>
